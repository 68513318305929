import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { neighbourhoods, options } from '../gis-data-upload/gis-data-upload.component';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { Router } from '@angular/router';
import { GisDataService } from '../gis-data-upload/gis-data-upload.service';
import { NotificationService } from '../services/notification.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/admin-breadcrumb.service';
import { JsonToCsv } from "../services/jsonToCsv";
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>gisapi_blocks_json_get</li>
 * <li>gisapi_places_bulk_search_post</li>
 * <li>umsapi_user_resource_post</li>
 * <li>gisapi_neighbourhoods_json_get</li>
 * <li>gisapi_layer_uploads_post</li>
 * <li>gisapi_layer_uploads_status_get</li>
 * <li>gisapi_layer_uploads_upload_id_accept_post</li>
 * <li>gisapi_layer_uploads_upload_id_reject_post</li>
 * <li>gisapi_layer_language_uploads_post</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-legacy-data-upload',
  templateUrl: './legacy-data-upload.component.html',
  styleUrls: ['./legacy-data-upload.component.css']
})
export class LegacyDataUploadComponent implements OnInit {

  options: options[] =
    [{ label: 'Legacy Parcels', value: 'legacy_parcels' },
    { label: 'Layer Data', value: 'layer_data' }
    ];
  language_list: any = [
    { language_id: 1, name: "English" },
    { language_id: 2, name: "Arabic" }
  ]
  layer_list: any = [
    { layer_name: "parcel", layer_label: "Parcel Data" },
    { layer_name: "street", layer_label: "Street" },
     {layer_name: "project", layer_label:"Project"}
  ]

  status: any[] = [
    { status_code: -1, value: 'Invalid' },
    { status_code: 1, value: 'Valid' },
    { status_code: 2, value: 'Accepted' },
    { status_code: -2, value: 'Rejected' },
    { status_code: 0, value: 'Uploaded' },
    { status_code: -3, value: 'Accept Invalid' },
    { status_code: 4, value: 'Accept Progress' }
  ];
  searchNeigh: String = '';
  fileToUpload: File = null;

  layer:string;

  fileUploadForm: FormGroup;

  selected_layer: FormControl;
  selected_lang: FormControl;
  selected_neighbourhood: FormControl;
  public selectMultiForm: FormControl;
  filterLayer = 'parcel';
  selectedlayer: any;
  loading: boolean = false;
  loader: boolean = false;
  height: any;
  flag: boolean = false;
  limit: number = 10;
  offset: number = 0;
  record: number;
  disableNext: boolean = false;
  disablePriv: boolean = true;
  pageNo: number = 1;
  sn: number = 1;
  count: number = 0;
  fileName: string = '';
  upload_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  is_admin = false;
  dataLength: number;
  pages: any = [];
  total: number;
  sizeOptions: string = '5, 10, 15, 25';
  pageSizeOptions: any;
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  public filterOption: Observable<any[]>;
  searchFilter: FormControl;
  neighbourhoods: neighbourhoods[] = [];
  legacyDataReport: any = [];
  blocks: any = [];
  buildings: any = [];
  loader1: boolean = false;
  selectedYear: string = new Date().getFullYear().toString();
  selectedLanguage: number = 1; // Default to English
  @ViewChild('myFileInput') myFileInput;

  @ViewChild(MatPaginator) paginator: MatPaginator
  attributes: any = [];
  // selectedAttirbutes: any = [];
  selected_attribute: any = [];
  select_row: any = [];
  acceptLoader: boolean = false;
  constructor(private csv: JsonToCsv, private api: ApiserviceService, private fnc: CommonfunctionService, private menus: AdminMenusService, private _crumbs: AdminBreadcrumbService, private fb: FormBuilder,
    private notify: NotificationService, private active: GisDataService, private route: Router) {
    this.menus.setActive('legacyDataUpload');
    this.active.setActive('');
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: 'Upload Data' };
    this._crumbs.mobiletitle = 'Upload Data';
    this._crumbs.count = 1;
    this.searchFilter = new FormControl();
    this.selectMultiForm = new FormControl();
    this.fileUploadForm = new FormGroup({
      selected_lang: new FormControl(null),
      selected_layer: new FormControl(null),
      selected_neighbourhood: new FormControl(null),
      layer: new FormControl('', [Validators.required]),
      file: new FormControl(null, [Validators.required])
    });
    this.onResize();
    this.getAllowResourcesApi();
    this.pageSizeOptions = this.sizeOptions.split(',').map(str => +str);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.height = (window.innerHeight - 80);
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event) {
    if (this.loading) {
      if (confirm("File upload is still under progress! If you leave, your changes will be lost.")) {
        return true;
      } else {
        return false;
      }
    }
  }

  // attributeEntity(){
  //   let url=`parcels/attributes-entity`
  //
  // }

  getBlocks(neighUid) {
    this.loader1 = true;
     let url = `blocks/json?neigh_uid=${neighUid}&return_geometry=true`;
    if (this.selectedYear) {
      url += `&year=${this.selectedYear}`;
    }
    this.api.getGpsData(`blocks/json?neigh_uid=${neighUid}&return_geometry=true`).subscribe((res: any) => {
      this.blocks = res;
      this.loader1 = false;
      this.buildings = [];
      //   this.selectedBuilding = null;
    }, err => {
      this.blocks = [];
      this.buildings = [];
      //   this.selectedBuilding = null;
      //  this.selectedBlock = null;
      this.loader1 = false;
      this.notify.notify('No Blocks found in this Neighbourhood', 'error');
    })
  }
  getBuildings(blockData) {
    this.loader1 = true;
    let body = {
      "columns": [
        "key", "geom"
      ],
      "data": [
        [
          "area", blockData.geom
        ]
      ]
    };
    this.api.postGmsData(`/places/bulk/search`, body).subscribe((res: any) => {
      let format = res;
      // format = JSON.parse(format);
      if (format.buildings.area) {
        this.buildings = format.buildings.area;
      } else {
        this.buildings = [];
        this.notify.notify('No Buildings found in this Block', 'warn');
        //    this.selectedBuilding = null;
      }
      this.loader1 = false;
    }, err => {
      this.buildings = [];
      // this.selectedBuilding = null;
      this.loader1 = false;
      this.notify.notify('No Buildings found in this Block', 'error');
    })
  }


  ngOnInit() {
    this.fileUploadForm.valueChanges.subscribe((data) => {
      if (data.layer) this.layer = data.layer;
    })
  }

  selectionChange(event, value) {
    console.log(event, value);

    if (event && !this.selected_attribute.includes(value)) {
      this.selected_attribute.push(value);
    } else {
      const index = this.selected_attribute.indexOf(value);
      if (index > -1) {
        this.selected_attribute.splice(index, 1);
      }
    }
    this.selectMultiForm.patchValue(this.selected_attribute);
    // this.searchFilter.patchValue('');
  }

  private _filter(name: string): String[] {
    const filterValue = name?.toLowerCase();
    let filteredList = (this.attributes && this.attributes.length > 0) ? this.attributes.filter(option => option.attribute_name.toLowerCase().includes(filterValue)) : [];
    return filteredList;
  }

  check(v) {
    if (this.options.length == 1) {
      this.fileUploadForm.patchValue({ 'layer': this.options[0].value });
      return true;
    } else {
      return false;
    }
  }
  initValidators(e) {
    this.fileName = '';
    this.fileUploadForm.patchValue({
      selected_lang: '',
      selected_layer: '',
      selected_neighbourhood: '',
      file: ''
    })
    this.myFileInput.nativeElement.value = '';
    this.gisAttributes("parcel");
  }
  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
        this.getNeighbourhoods();

      })
  }
  // getAllowResourcesApi() {
  //   this.api.getUserRole(); // getting role assign to this user
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     // .map((data:any) => data.json())
  //     .subscribe(
  //       (data: any) => {
  //         this.api.allowResources = data;
  //         this.getAllowResource();
  //         this.getNeighbourhoods();
  //       },
  //       err => {
  //       }
  //     )
  // }

  handleFileInput(event) {
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.notify.notify('Unsupported file type', 'warn');
        this.fileUploadForm = new FormGroup({
          file: new FormControl(null, [Validators.required])
        })
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    }
  }

  getAllowResource() {
    this.upload_access = this.fnc.checkResourceAccess('legacy_parcels', false);
  }



  getNeighbourhoods() {
    this.loader1 = true;
    this.neighbourhoods=[];
    this.api.getGpsData('neighbourhoods/json')
      .subscribe({next: (data: any) => {
        this.loader1 = false;
        if (data.length) {
          data.forEach(e => {
            this.neighbourhoods.push(e);
          });
        }
      }, error: (err) => {
        this.loader1 = false;
      }});
  }

  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (obj[key] == val && flag) {
          res = obj;
          flag = false;
        }
      });
    }
    return res;
  }

  uploadFile(val) {
    if (!this.upload_access.POST) {
      this.notify.notify("You are not authorized to upload GIS data", "warn");
      return;
    }
    //let ln = this.fileToUpload.size;
    let layer = null, uid = null, url, selected_lang = null, selected_layer = null, selected_neigh = null;
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    layer = val.value.layer;
    if (layer == 'layer_data') {
      selected_layer = val.value.selected_layer;
      selected_lang = val.value.selected_lang;
      selected_neigh = val.value.selected_neighbourhood
      if (selected_layer == null || selected_layer == '') {
        this.notify.notify("Layer is required", "warn");
        return;
      } else if (selected_lang == null || selected_lang == '') {
        this.notify.notify("Language is required", "warn");
        return;
      } else if (selected_neigh == null || selected_neigh == '') {
        this.notify.notify("Neighbourhoods is required", "warn");
        return;
      }
      // url = `${selected_layer}/${selected_lang}/uploads?user_id=${this.api.user_id}&neigh_uid=${selected_neigh}`;
      url = `${layer}/layer-data-upload?user_id=${this.api.user_id}&neigh_uid=${selected_neigh}&layer=${selected_layer}&language_id=${this.fileUploadForm.value.selected_lang}`
    } else {
      url = layer + '/uploads?uid=' + uid + '&added_by=' + this.api.user_id;
    }
    this.loading = true;
    this.api.postFile(url, formData).subscribe({next: (res: any) => {
      this.loading = false;
      this.notify.notify('File successfully uploaded!', 'success');
      this.fileToUpload = null;
      this.fileName = '';
      this.myFileInput.nativeElement.value = '';
      this.fileUploadForm.patchValue({
        file: ''
      })
      //let e = { value: layer }
     // this.initValidators(e);
      setTimeout(() => {
        if (this.selectedlayer != null) {
          this.getUploadedFilesList(this.selectedlayer, false);
        }
      }, 1000);
    }, error: (err) => {
      this.fileToUpload = null;
      this.fileName = '';
      this.myFileInput.nativeElement.value = '';
      this.fileUploadForm.patchValue({
        file: ''
      })
      //let e = { value: layer }
      //this.initValidators(e);
      this.loading = false;
    }})
  }

  uploadData(e) {
    this.selectedlayer = e;
    if(this.fnc.checkResourceAccess('all_uploaded_file').GET){
      this.is_admin = true;
    }
    let url = '';
    if (this.selectedlayer == 'layer_data') {
      if (this.filterLayer == '' || this.filterLayer == null) {
        return
      }
      url = this.filterLayer + '/layer-data-upload?added_by=' + this.api.user_id + '&limit=1000';
      if (this.is_admin) {
        url = this.filterLayer + '/layer-data-upload';
      }
    } else {
      url = this.selectedlayer + '/uploads/status?added_by=' + this.api.user_id + '&limit=1000';
      if (this.is_admin) {
        url = this.selectedlayer + '/uploads/status';
      }
    }

    this.api.getGmusData(url).subscribe((res: any) => {
      this.dataLength = res.length;
      this.total = res.length;
    })
  }



  getDate(date) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDateUTC(date, true);
    } else {
      return '';
    }

  }
  getStatus(val) {
    return this.getArrayValue('status_code', val, this.status).value;
  }
  getActive_(e) {
    return this.active.getIsActive(e.value);
  }

  selectdata(e) {
    this.filterLayer = e.source.value;
    this.getUploadedFilesList(this.selectedlayer, true);
  }
  onLanguageChange(event) {
    this.selectedLanguage = event.value;
    this.getUploadedFilesList(this.selectedlayer, true);
}
  selectedLayer(e) {
    this.gisAttributes(e);
    // if (e !== 'layer_data') {
      // Reset the attributes dropdown value if the selected layer is not 'layer_data'
       // Explicitly reset the multi-select dropdown
       this.selectMultiForm.setValue([]);
       this.clearDropdownState();
  }
   // Method to clear dropdown internal state
   clearDropdownState(): void {
    const element = document.querySelectorAll('.mat-select-trigger .mat-option') as NodeListOf<HTMLElement>;
    element.forEach((el) => {
      el.classList.remove('mat-selected');
    });
  }
  getUploadedFilesList(val, flag: boolean) {
    // this.uploadData(val);
    if(this.fnc.checkResourceAccess('all_uploaded_file').GET){
      this.is_admin = true;
    }

    if (!this.upload_access.GET) {
      this.notify.notify("You not authorized to view uploaded file of GIS", "warn");
      this.legacyDataReport = [];
      return;
    }
    let url = '';
    this.record = 0;

    this.selectedlayer = val;
    this.active.setActive(this.selectedlayer);
    if (flag) {
      this.offset = 0;
      this.pageNo = 1;
    }
    let limit = this.limit;
    let offset = this.offset;
    if (this.selectedlayer == 'layer_data') {
      if (this.filterLayer == '' || this.filterLayer == null) {
        return
      }
      url = this.filterLayer + '/layer-data-upload?added_by=' + this.api.user_id + '&limit=' + limit + '&offset=' + offset +'&language_id=' + this.selectedLanguage;
      if (this.is_admin) {
        url = this.filterLayer + '/layer-data-upload?limit=' + limit + '&offset=' + offset +'&language_id=' + this.selectedLanguage;
      }
    } else {
      url = this.selectedlayer + '/uploads/status?added_by=' + this.api.user_id + '&limit=' + limit + '&offset=' + offset;
      if (this.is_admin) {
        url = this.selectedlayer + '/uploads/status?limit=' + limit + '&offset=' + offset;
      }
    }
    if (offset == 0) url += `&is_count=true`
    this.loader = true;
    this.api.getGmusData(url).subscribe((res: any) => {
      this.loader = false;
      if (offset == 0) this.total = res?.totalCount;
      this.select_row = [];
      this.record = res.length;
      this.count = this.offset;
      this.legacyDataReport = [];

      if (res.data.length) {
        res.data.forEach(e => {
          this.sn = ++this.count;
          let d = {
            sn: this.sn,
            name: e.name,
            file_name: e.file_name,
            added_date: this.getDate(e.added_date),
            status: this.getStatus(e.status),
            errfile_link: e.errfile_link,
            logfile_link: e.logfile_link,
            accept_errfile_link: e.accept_errfile_link,
            accept_logfile_link: e.accept_logfile_link,
            upload_id: e.upload_id,
            user_name: e.added_by,
            existing_count: e.existing_count,
            add_count: e.add_count,
            delete_count: e.delete_count,
            update_count: e.update_count,
            street_delete_count: e.street_delete_count,
            check: (Number(e.existing_count)) - (Number(e.update_count) + Number(e.delete_count)),
          }
          this.legacyDataReport.push(d);

        });
      } else {
        this.legacyDataReport = [];
        this.loader = false;
        this.notify.notify('No results found', 'warn');
      }
    }, err => {
      this.select_row = [];
      this.loader = false;
      this.legacyDataReport = [];
      this.notify.notify("No results found", 'error');
    })
  }

  isLogView(v) {
    if (v == 'Valid' || v == 'Uploaded' || v == 'Invalid' || v == 'Rejected') {
      return true;
    }

  }
  acceptedlog(v) {
    if (v == 'Accepted' || v == 'Accept Invalid' || v == 'Accept Progress') {
      return true;
    }
  }

  isAccepted(v) {
    if (v == 'Valid') {
      return true;
    } else {
      return false;
    }
  }
  isRejected(v) {
    if (v == 'Invalid' || v == 'Valid') {
      return true;
    }
  }
  showStopbutton(status) {
    if (status == 'Accept Progress' || status == 'Uploaded') {
      return true;
    }
  }
  checkDis(id) {
    if (this.select_row.indexOf(id) > -1 && this.acceptLoader) {
      return true;
    }
    return false;
  }
  action(data, flag) {
    if (!this.upload_access.PATCH) {
      this.notify.notify("You are not authorized to update GIS information", "warn");
      return;
    }
    let url: string;

    if (flag == 'accept') {
      if (this.selectedlayer == 'layer_data') {
        if (this.filterLayer == '' || this.filterLayer == null) {
          return;
        }
        url = this.filterLayer + '/uploads/' + data.upload_id + '/accept?accepted_by=' + this.api.user_id;
      } else {
        url = this.selectedlayer + '/uploads/' + data.upload_id + '/accept?accepted_by=' + this.api.user_id;
      }
      this.select_row.push(data.upload_id);
      this.acceptLoader = true;
      setTimeout(() => {
        this.getUploadedFilesList(this.selectedlayer, false);
      }, 1000);
      this.api.postUserAction(url, null).subscribe((res: any) => {
        this.acceptLoader = false;
        this.getUploadedFilesList(this.selectedlayer, false);
        if (res) {
          this.notify.notify('File Accepted', 'success');
        } else {
          this.notify.notify('File Not Accepted', 'error');
        }
      }, err => {
        this.acceptLoader = false;
        this.getUploadedFilesList(this.selectedlayer, false);
        this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
      })


    } else {
      if (this.selectedlayer == 'layer_data') {
        if (this.filterLayer == '' || this.filterLayer == null) {
          return
        }
        url = this.filterLayer + '/uploads/' + data.upload_id + '/reject?rejected_by=' + this.api.user_id;
      } else {
        url = this.selectedlayer + '/uploads/' + data.upload_id + '/reject?rejected_by=' + this.api.user_id;
      }
      this.loader = true;
      this.api.postUserAction(url, null).subscribe((res: any) => {
        this.loader = false;
        this.getUploadedFilesList(this.selectedlayer, false);
        if (res) {
          this.notify.notify('File Rejected', 'success');
        } else {
          this.notify.notify('File Not Rejected', 'error');
        }
      }, err => {
        this.notify.notify(this.fnc.getErrorMessage(err.error), 'error');
        this.getUploadedFilesList(this.selectedlayer, false);
        this.loader = false;
      })
    }
  }

  // stopprocess api call
  stopprocess(data, process) {
    let processName = '';
    if (this.selectedlayer == 'layer_data') {
      processName = 'gis_layer_data_validate';
    }

    let url = `stop-process?uid=${data.upload_id}&user_id=${this.api.user_id}&process_name=${processName}`;
    // if (process == '')
    this.loader = true
    this.api.getGmsData(url).subscribe((res) => {
      this.loader = false
      if (res) {
        this.getUploadedFilesList(this.selectedlayer, false);
        this.notify.notify("Stop process successful", "success", 3000);
      } else {
        this.notify.notify("Stop process unsuccessful", "error", 5000);
      }
    }, err => {
      this.loader = false;
      this.notify.notify(err.error.message, "error", 5000);
    })
  }
  //download csv
  // selectedAttributes(value) {
  //   this.selectedAttirbutes = value;
  // }

  gisAttributes(layer) {
    let url = `${layer}/attributes-entity?&status=1`
    this.api.getGmsData(url).subscribe((res: any) => {
      this.attributes = res.data;
      this.filterOption = this.searchFilter.valueChanges.pipe(
        startWith<string>(''),
        map(name => this._filter(name))
      )
    })
  }

  downloadCsv() {
    let data = this.fileUploadForm.get('layer').value;
    let selectedLayer = this.fileUploadForm.get("selected_layer").value;
    const timestamp = new Date().toISOString().replace(/[-T:.Z]/g, ''); // Generate timestamp

    if (data == 'layer_data') {
      if (this.selected_attribute.length < 1) {
        this.notify.notify("Please Select Atleast One Attibute from the list to Download template", "warn", 8000);
        return;
      }
      let csvData = [], attr, atrId = {}, atrType = {};
      this.selected_attribute.forEach(element => {
        attr = this.fnc.getArrayValue('attribute_name', element, this.attributes);
        atrId[element] = attr.attribute_id;
        atrType[element] = attr.data_type;
      });
      csvData.push(atrId, atrType);

      // Adding hardcoded attribute
      let header = [];
      header = this.selected_attribute;
      header.splice(0, 0, 'uid', 'date');

      // Append timestamp to the file name
      const fileName = `${selectedLayer}_${timestamp}`;
      this.csv.downloadFile(csvData, fileName, header);

      this.selected_attribute = [];
    } else {
      let str = 'parcel_uid,submitted_Date,vacant,setback,parking,near_mosq,near_elect,road_no,highway_ac,build_col,building_s,survey_req,near_coop,update_fro,nearest_highway,parcel_no,house_no,category,sub_category';
      let list = str.split(',');

      // Append timestamp to the file name
      const fileName = `Template_Legacy_Upload_${timestamp}`;
      this.csv.downloadFile(null, fileName, list);
    }
  }

  ////

  pageAction(e) {
    this.pageIndex = e.pageIndex;
    if (this.limit != e.pageSize) {
      this.limit = e.pageSize;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    this.getUploadedFilesList(this.selectedlayer, false);
  }

}
