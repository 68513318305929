<div class="table-wrapper" *ngIf="data?.length != 0">
  <table mat-table [dataSource]="data" class="">
    <!-- Dynamic Columns -->
    <ng-container *ngFor="let column of columns" [matColumnDef]="column">
      <!-- Checkbox Column -->
      <ng-container *ngIf="column === 'checkbox'">
        <th mat-header-cell *matHeaderCellDef class="checkbox">
          <mat-checkbox
            (change)="toggleAllRows($event)"
            [checked]="isAllSelected()"
            [indeterminate]="isSomeSelected()"
            [disabled]="data.length === 0"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            (change)="toggleRow(element)"
            [checked]="isRowSelected(element)"
            [disabled]="data.length === 0"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container *ngIf="column !== 'checkbox'">
        <th mat-header-cell *matHeaderCellDef>
          {{ column | removeUnderscore }}
        </th>
      </ng-container>

      <td mat-cell *matCellDef="let element">
        <!-- Log Buttons -->
        <ng-container *ngIf="column === 'log'">
          <div class="button-wrapper">
            <button
              *ngFor="let button of buttons.log"
              (click)="button.action(element)"
              [ngStyle]="button.style"
              [ngClass]="button.class"
              [disabled]="button.label === 'Accept Log' && (element.status !== 2 && element.status !== -3 && element.status !== 4)"
            >
              {{ button.label }}
            </button>
          </div>
        </ng-container>

        <!-- Action Buttons -->
        <ng-container *ngIf="column === 'action'">
          <div class="button-wrapper">
            <ng-container *ngFor="let button of buttons.action">
              <button
                *ngIf="button.label !== 'Stop Process' || element.status === 3"
                class="action-btn "
                (click)="button.action(element)"
                [ngStyle]="button.style"
                [disabled]="isButtonDisabled(element, button.label)"
                [ngClass]="button.isActionColumnClass"
              >
               {{ button.label }}
                <img
                  [ngStyle]="button.style"
                  *ngIf="button.icon"
                  [src]="button.icon"
                  alt="delete"
                />

              </button>
            </ng-container>
          </div>
        </ng-container>

        <!-- query Buttons -->
        <ng-container *ngIf="column === 'query'">
          <div class="button-wrapper">
            <ng-container *ngFor="let button of buttons.query">
              <button
                class="action-btn"
                (click)="button.action(element)"
                [ngStyle]="button.style"
                [disabled]="isButtonDisabled(element, button.label)"
              >
                {{ button.label }}
                <img
                  [ngStyle]="button.style"
                  *ngIf="button.icon"
                  [src]="button.icon"
                  alt="delete"
                />
              </button>
            </ng-container>
          </div>
        </ng-container>

        <!-- conflict Buttons -->
        <ng-container *ngIf="column === 'conflict' || column === 'attributes'">
          <div class="button-wrapper">
            <ng-container *ngFor="let button of buttons.conflict">
              <button
                class="action-btn"
                (click)="button.action(element)"
                [ngStyle]="button.style"
                [disabled]="isButtonDisabled(element, button.label) || (!element?.data?.cities?.length && column === 'attributes')"
              >
                {{ button.label }}
                <img
                  [ngStyle]="button.style"
                  *ngIf="button.icon"
                  [src]="button.icon"
                  alt="delete"
                />
              </button>
            </ng-container>
          </div>
        </ng-container>

        <!-- Status Display -->
        <ng-container *ngIf="column === 'status'">
          <span
            [ngStyle]="{
              color: getStatusClass(element.status).color,
              'white-space': 'nowrap'
            }"
          >
            {{ getStatusClass(element.status).class }}
          </span>
        </ng-container>

        <!-- Default Data Display -->
        <ng-container
          *ngIf="
            column !== 'log' &&
            column !== 'action' &&
            column !== 'status' &&
            column !== 'query' &&
            column !== 'conflict' &&
            column !== 'attributes'
          "
        >
          <div
            matTooltip="{{ formatValue(element[column]) }}"
            matTooltipDisabled="{{ !isTooltipNeeded(element[column]) }}"
          >
            {{ formatDisplayValue(element[column]) }}
          </div>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns"></tr>
  </table>
</div>
<div *ngIf="totalCount && totalCount > 0" class="pagination-wrapper">
  <mat-paginator
    [length]="totalCount"
    showFirstLastButtons
    [pageSizeOptions]="pageSizeOptions"
    (page)="pageAction($event)"
  >
  </mat-paginator>
</div>
<div class="no-data" *ngIf="data?.length == 0">
  <div><img src="../../../../../assets/icon/no-data-found.svg" alt="" /></div>
  <p>No Records Found</p>
</div>
