import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { NotificationService } from '../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { AddAttributesComponent } from './add-attributes/add-attributes.component';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { debounceTime } from 'rxjs/operators';
import { CopyAttributesComponent } from './copy-attributes/copy-attributes.component';




@Component({
  selector: 'app-gis-attributes',
  templateUrl: './gis-attributes.component.html',
  styleUrls: ['./gis-attributes.component.css']
})
export class GisAttributesComponent implements OnInit {
  public attribute_access = {
    'GET': false,
    'POST': true,
    'PATCH': false,
    'DELETE': false
  };
  displayedColumns = [
    'checkbox',
    // 'SN',
    'layer',
    'attribute_name',
    'data_type',
    'added_date',
    'action',]
  limit: number = 10;
  offset: number = 0;
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  dataSource: any = [];
  searchField: FormControl;
  loader: boolean = false;
  status: string = "1";
  public selected_attribute;
  selectedRows: any[];
  returnVal(element, dispCol) {
    switch (dispCol) {
      case 'Layer': return element.layer;
      case 'Attribute Name': return element.attribute_name;
      case 'Data Type': return element.data_type;
      case 'Added Date': return this.getdateForm(element.added_date);
    }
  }

  constructor(private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private menus: AdminMenusService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private notify: NotificationService) {
    this.menus.setActive('gisatrributes');
    this.getAllowResourcesApi();
  }

  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.attribute_access = this.fnc.checkResourceAccess('gis_attributes', false);
        this.getAttributes();
       // this.getAttributeCount();

      })
  }
  // getAllowResourcesApi() {
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe((data: any) => {
  //       this.api.allowResources = data;
  //       this.attribute_access = this.fnc.checkResourceAccess('attributes', false);
  //       this.getAttributes();
  //       this.getAttributeCount();

  //     }, err => {
  //     })
  // }

  // API[/attributes] call to GET Saved Attributes
  getAttributes(searchterm = null) {
    if (!this.attribute_access.GET) {
      this.notify.notify("You are not authorized to VIEW Gis Attributes", "warn");
      return;
    }
    this.selectedRows = null;
    this.loader = true;
    let url = `attributes?is_lang_dependent=false&status=${this.status}&sort_by=attribute_name&sort_asc=true&limit=${this.limit}&offset=${this.offset}`;
    if (searchterm != null) {
      url += `&attribute_name=${searchterm}`
    }
    if(this.offset == 0){
      url +="&is_count=true"
    }

    this.api.getGmsData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        if (this.offset == 0) {
          this.totalCount = res.totalCount;
        }
        if (res && res.data.length) {
          this.dataSource = res.data;
        }else{
          this.dataSource = [];
        }
      },
      error: () => {
        this.dataSource = [];
        this.loader = false;
      },
    });
  }

  openDialog(type: any, data: any = null) {
    if (type == 'add' && !this.attribute_access.POST) {
      this.notify.notify("You are not authorized to ADD Gis Attributes", "warn");
      return;
    } if (type == 'edit' && !this.attribute_access.PATCH) {
      this.notify.notify("You are not authorized to EDIT Gis Attribute", "warn");
      return;
    }
    if (type) {
      var _data = {
        type: type,
        edit: data,
        pdata: this
      }
      const dialogRef = this.dialog.open(AddAttributesComponent, {
        width: '400px',
        data: _data
      });

      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }
  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDateUTC(date, true);
    }
    else {
      return '';
    }
  }
  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getAttributes();
  }
  //GET /Attribute count API for data length for pagination
  // getAttributeCount() {
  //   this.totalCount = null;
  //   this.offset = 0;
  //   let url = `attributes?status=${this.status}`;
  //   this.api.getGisData(url).subscribe((res: any) => {
  //     if (res) {
  //       this.totalCount = res.length;
  //     }
  //   }, err => {
  //   })
  // }

  openConfirmation(value) {
    if (!this.attribute_access.DELETE) {
      this.notify.notify("You are not authorized to DELETE Gis Attributes", "warn");
      return;
    }
    this.selected_attribute = value;
    let _data = {
      parent_data: this,
      message: 'Do you to delete Attribute?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  //patch[Delete] API for /attributes
  confirmDialogYes(confirm) {
    let url = `attributes/${this.selected_attribute.attribute_id}/${this.api.user_id}`;
    if (confirm == 'YES') {
      this.api.deleteGmsData(url).subscribe((data: any) => {
        this.notify.notify("Attribute Delete Successful", "success");
        this.getAttributes();
        //this.getAttributeCount();

      }, err => {
        this.notify.notify("Unsuccessful Attribute Deletion", "error")
      })
    }
  }

  //get Deleted Attributes
  getDeleted(event) {
    if (!this.attribute_access.GET) {
      this.notify.notify("You are not authorized to VIEW Gis Attributes", "warn");
      return;
    }
    if (event.checked == false) {
      this.status = "1";
    }
    else {
      this.status = "-1";
    }
  //  this.getAttributeCount();
    this.getAttributes();
  }

  //update attribute status
  updateStatus(data, event) {
    if (!this.attribute_access.PATCH) {
      event.source.checked = data.status == 1?true:false;
      this.notify.notify("You are not authorized to UPDATE Attribute STATUS", "warn");
      return;
    }
    let url = `attributes`;
    let body: any = {
      "attributeId": data.attribute_id,
      "attributeName": data.attribute_name,
      "dataType": data.data_type,
      "layer": data.layer,
      "status": (event.checked == true) ? 1 : 0,
      "addedBy": this.api.user_id,
    };
    this.api.patchGmsData(url, body).subscribe((res: any) => {
      if (res.status == 201) {
        this.notify.notify(`${res.message}`, "success");
        this.getAttributes();
      }
      else {
        event.source.checked = data.status == 1?true:false;
        this.notify.notify(`${res.message}`, "warn");
      }
    }, err => {

    })
  }
  onPageChange(event: any) {
    this.limit = event.limit; // Update limit based on pagination event
    this.offset = event.offset; // Update offset based on pagination event
    this.getAttributes();
  }
  onSelectedRowsChange(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    // Handle selected rows (e.g., perform batch actions)
  }
    // Configuration object for button actions and styles
    buttonConfig = {
      action: [
        {
          // label:'edit',
          action: (element:any) => this.openDialog('edit',element), // Function to accept request
          icon: "assets/icon/edit-icon.svg",
          style: {
            backgroundColor: "#fff",
            marginRight:"20px"
          },
          class: "action-button",
          isActionColumnClass:'btnIconClass'
        },
        {
          // label:'delete',
          action: (element:any) => this.openConfirmation(element), // Function to accept request
          icon: "assets/icon/delete-icon.svg",
          style: {
            width: "18px",
            backgroundColor: "#fff",
          },
          class: "action-button",
           isActionColumnClass:'btnIconClass'
        },
      ],
    };
    copyAttribute(){
      if(!this.attribute_access.POST){
        this.notify.notify("You are not authorized to copy attribute","warn");
        return
        }
      const dialogRef = this.dialog.open(CopyAttributesComponent, {
        width: '450px',
        data: this.selectedRows,
        disableClose:true
      });
      dialogRef.afterClosed().subscribe(result => {
        if(result){
          this.getAttributes();
          this.selectedRows = null
        }else{
          this.getAttributes();
        }
      });
    }
  ngOnInit() {
    this.searchField = new FormControl();
    this.searchField.valueChanges
    .pipe(debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        if (term) {
          this.getAttributes(term);
        } else {
          this.getAttributes(null);
        }
      })
  }

}
