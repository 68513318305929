// layer-options.model.ts
export interface LayerOption {
  layerName: string;
  value: string;
  url: string;
}

export const layerListOptions: LayerOption[] = [
  {
    layerName: "Parcel Clusters",
    value: "cluster",
    // url: "calculate-parcel-cluster-spark"
     url: "spark-calculation"
  },
  {
    layerName: "Direction",
    value: "direction",
    // url: "calculate-direction-spark"
     url: "spark-calculation"
  },
  // {
  //   layerName: "Highway/Street facing",
  //   value: "highway_rays_90",
  //   url: "calculate-highway-facing-spark"
  // },
  {
    layerName: "Street Facing Rays",
    value: "street_rays_180",
    // url: "calculate-street-facing-180-spark"
     url: "spark-calculation"
  },
  {
    layerName: "Parcel Visibility",
    value: "parcel_visibility",
    // url: "calculate-parcel-visibility-spark"
     url: "spark-calculation"
  },
  {
    layerName: "Setback",
    value: "setback",
    // url: "calculate-setback-spark"
     url: "spark-calculation"
  },
  {
    layerName: "Amenity Distance",
    value: "amenity_distance",
    url: "spark-calculation"
  },
  {
    layerName: "Sikka Parcel",
    value: "sikka_parcel",
    url: "spark-calculation"
  }
];
