<div class="check-sync-update-container">
  <button type="button" class="check-update" (click)="syncUpdateStuts()">
    <img src="../../../assets/icon/check-update.svg" alt="">
    <span  class="badge" *ngIf="selectedData?.length > 0"> {{selectedData?.length}} </span>
  </button>

  <div class="custom-model" *ngIf="isModelOpen">
    <h5>Spark Sync Update</h5>

    <div class="table-data-container">
      <app-gis-data-table
        [data]="selectedData"
        [columns]="selectedColumns">
      </app-gis-data-table>
    </div>

    <!-- Footer Buttons -->
    <div class="side-panel-footer gap-24 d-flex">
      <app-gis-primary-button
        [button_label]="'Close'"
        [class]="'cancel-btn-model'"
        [isDisabled]="false"
        (click)="closeModel()">
      </app-gis-primary-button>

      <app-gis-primary-button
        [button_label]="'Calculate'"
        [class]="'calculate-model'"
        [isDisabled]="false"
        (onAction)="calculateSyncUpdate();">
      </app-gis-primary-button>
    </div>
  </div>
</div>
<ngx-loading
  [show]="loader"
  [config]="{ backdropBackgroundColour: 'rgba(255,255,255,0.3)' }"
></ngx-loading>
