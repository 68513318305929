import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";

@Component({
  selector: "app-check-spark-sync-update",
  templateUrl: "./check-spark-sync-update.component.html",
  styleUrls: ["./check-spark-sync-update.component.scss"],
})
export class CheckSparkSyncUpdateComponent {
  @Input() selectedData: any;
  @Input() resouceCheck: any;
  @Output() syncEvent = new EventEmitter<void>();
  @Output() isCalculating = new EventEmitter<void>();
  isModelOpen: boolean = false; // Initially hidden
  selectedColumns: string[] = ["neighborhood_name", "block_name","change_type",  "year"];
  loader:boolean = false;
  // selectedData = []//[ { "status": 0, "city_id": 52, "message": "Failed attribute: 002_attribute_for_copy (Error code: 0)", "city_name": "Switzerland" }, { "status": 0, "city_id": 52, "message": "Failed attribute: 003_test_for_attribute (Error code: 0)", "city_name": "Switzerland" } ]
  constructor(
    private apiService: ApiserviceService,
    private notify: NotificationService
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedData && changes.selectedData.currentValue) {
      console.log("Received selectedData in child:", this.selectedData);

      // Open modal only if selectedData is valid
      if (Array.isArray(this.selectedData) && this.selectedData.length > 0) {
        // this.isModelOpen = true;
      } else {
        this.isModelOpen = false;
      }
    }
  }
  ngOnInit(): void {
    console.log(this.selectedData, "selectedData");
  }

  calculateSyncUpdate() {
    if(!this.resouceCheck.POST){
      this.notify.notify("You are not authorized to get data", "warn");
      return
    }
    let body = {};
    let apiUrl = `auto-calculation`;
  this.loader = true;
    this.apiService.postGesData(apiUrl, body).subscribe({
      next: (response: any) => {
        this.loader = false;
        if (response?.status === 200) {
          console.warn("response:", response);
          this.isCalculating.emit();
          this.selectedData = []; // Clear data after calculation
          this.toggleModel();
        } else {
          console.warn("Unexpected response:", response);
          this.loader = false;
        }
      },
      error: (error) => {
        console.error("API Error:", error);
        this.loader = false;
        // Handle errors here
      },
      complete: () => {
        console.log("API call completed.");
        this.loader = false;
      },
    });
  }
  toggleModel() {
    this.isModelOpen = !this.isModelOpen;
  }

  closeModel() {
    this.isModelOpen = false;
  }
  syncUpdateStuts() {
    console.log(this.selectedData, "this.selectedData");
    this.syncEvent.emit();

    setTimeout(() => { // Ensure data is updated before checking
      if (Array.isArray(this.selectedData) && this.selectedData.length > 0) {
        this.toggleModel();
      } else {
        this.notify.notify("There is no data for calculation.", "warn");
      }
    }, 100); // Delay check for update propagation
  }

}
