import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "../../../services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
@Component({
  selector: "app-gis-file-upload",
  templateUrl: "./gis-file-upload.component.html",
  styleUrls: ["./gis-file-upload.component.css"],
})
export class GisFileUploadComponent {
  @ViewChild("myFileInput") myFileInput;
  @Input() upload_access: any;
  @Input() title:string
  @Output() afterUpload  = new EventEmitter<any>(); // Event to emit data
  reselectfile: boolean = true;
  error_message: string = "";
  fileUploadForm: FormGroup;
  fileToUpload: File = null;
  fileName: string = "";
  private clickBlocked: boolean = false;
  uploadLoading: boolean = false;
  constructor(
    private notify: NotificationService,
    private api: ApiserviceService
  ) {
    this.fileUploadForm = new FormGroup({
      selectedneightUid: new FormControl(""),
      selectedBlockRoad: new FormControl(""),
      parcels: new FormControl(""),
      layer: new FormControl("", [Validators.required]),
      file: new FormControl(null, [Validators.required]),
    });
  }
  handleFileInput(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.notify.notify("Unsupported file type", "warn");
        this.fileUploadForm = new FormGroup({
          file: new FormControl(null, [Validators.required]),
        });
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    }
  }
  /**
 * This is a functionto upload file to the database.
eturns {} Returns promise with uploaded file link.
 */
  uploadFile() {
    if (!this.upload_access.POST) {
      this.notify.notify(
        "Access declined. You are not authorized to upload data.",
        "warn"
      );
      return;
    }
    if (this.uploadLoading || this.clickBlocked) return; // Block duplicate clicks
    this.clickBlocked = true; // Block further clicks temporarily

    if (!this.fileToUpload || (this.fileToUpload && !this.fileToUpload.name)) {
      this.notify.notify("Please select file", "warn");
      return;
    }
    this.error_message = "";
    let formData = new FormData();
    formData.append("file", this.fileToUpload, this.fileToUpload.name);
    let url = `upload-csv?added_by=${this.api.user_id}`;

    this.uploadLoading = true;
    this.fileToUpload = null;
    this.myFileInput.nativeElement.value = "";
    this.api.postFile(url, formData).subscribe({
      next: (res: any) => {
        this.clickBlocked = false;
        this.uploadLoading = false;
        this.reset();
        if (res && res.status == 201) {
          this.notify.notify("File successfully uploaded!", "success");
          this.afterUpload.emit()
        } else {
          this.notify.notify(res.message, "warn");
          this.clickBlocked = false;
          this.uploadLoading = false;
        }
      },
      error: (err) => {
        this.uploadLoading = false;
        this.fileName = "";
        this.clickBlocked = false;
      },
    });
  }
  reset() {
    this.fileName = "";
    this.fileToUpload = null;
    this.reselectfile = false;
    this.myFileInput.nativeElement.value = "";
  }
}
