import { ChangeDetectorRef, Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApiserviceService } from "../../apiservice.service";
import { NotificationService } from "../services/notification.service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { CommonfunctionService } from "../../services/commonfunction.service";
import * as _moment from "moment";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { GisDataService } from "../gis-data-upload/gis-data-upload.service";
import { Subject } from "rxjs";
import { layerListOptions } from "./model/layer-list-options.model";
import { StatusOptions } from "./model/status-options.model";
// tslint:disable-next-line:no-duplicate-imports
// import { default as  Moment } from 'moment';

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: "YYYY",
  },
  display: {
    dateInput: "YYYY",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
@Component({
  selector: "app-spark-calculate-sync",
  templateUrl: "./spark-calculate-sync.component.html",
  styleUrls: ["./spark-calculate-sync.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SparkCalculateSyncComponent {
  multiple:boolean=true;
  sub_categories: string[] = [];
  layerDataGroup: FormGroup;
  neighbourhoods: any[];
  selectedYear = new Date().getFullYear();
  loader: boolean = false;
  searchNeigh: string = "";
  blocks: any[];
  selectedBlock: any[] = [];
  selectedneightUid: any;
  layerListData: [] = [];
  layerTableContent = {};
  isPanelOpen = false;
  layerListOptions = layerListOptions;
  status_options = StatusOptions;
  selectedNeighs: any;
  startYear: number;
  currentYear: number;
  selected: void;
  offset: number = 0;
  limit: number = 10;
  pageIndex: number = 0;
  date = new FormControl(moment());
  totalCount: any;
  is_admin: boolean = false;
  filter_list_data: any;
  filteredStatus: any[];
  isCalculating: boolean = false;
  list: Subject<void> = new Subject<void>();
  calculate_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  isServerRunning: boolean = false;
  selectedData: any;
  constructor(
    private fb: FormBuilder,
    private api: ApiserviceService,
    private notify: NotificationService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private active: GisDataService,
    private menus: AdminMenusService,
    private fnc: CommonfunctionService,
    private cdr: ChangeDetectorRef
  ) {
    iconRegistry.addSvgIcon(
      "filter-icon",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icon/filter-icon.svg")
    );
    this.menus.setActive("spark-calculate-sync");
    this.active.setActive("");
  }

  ngOnInit() {
    this.init();
    this.currentYear = new Date().getFullYear();
    this.startYear = 2009;
    this.layerDataGroup.patchValue({
      layerName: this.layerListOptions[0].value,
    });
    this.getAllowResourcesApi();
  }

  /**
   * Form initialize
   */

  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe((res: any) => {
      this.api.allowResources = res.data;
      this.getAllowResource();
      this.getNeighbourhoods();
      this.getLayerData(null, null, true);
      this.getSyncStatus();
    });
  }

  getAllowResource() {
    this.calculate_access = this.fnc.checkResourceAccess(
      "spark_calculate_sync",
      false
    );
  }
  init() {
    this.layerDataGroup = this.fb.group({
      neighborhood: ["", Validators.required],
      year: [],
      layerName: [""],
      block: [{ value: null, disabled: false }],
      distance: [""],
      filter_neighborhood: [null],
      status: [null],
      sub_categories: [[]],
      zoning:[[]],


    });
  }
  /**
   * getting Neighborhood drop down list
   * @param notDefind
   */
  getNeighbourhoods() {
    this.neighbourhoods = [];
    let url = `neighbourhoods/json`;
    if (this.selectedYear) {
      url += `?year=${this.selectedYear}`;
    }
    this.loader = true;
    this.api.getGpsData(url).subscribe({
      next: (data: any) => {
        this.loader = false;
        if (data.length) {
          this.neighbourhoods = data;
        }
      },
      error: () => {
        this.loader = false;
      },
    });
  }

  /**
   *
   * @param neigh selecting neighbourhoods and send null block after select neighbourhood
   */
  selectedNeigh(neigh) {
    this.layerDataGroup.patchValue({
      block: "",
    });
    if (neigh.length) {
      this.selectedNeighs = neigh;
      this.getBlocks(neigh);
    } else {
      this.selectedNeighs = null;
    }
  }

  /**
   * get block list based on neigh id
   * @param neighUid
   */
  getBlocks(neighUid) {
    let url = `blocks/json?neigh_uid=${neighUid}&return_geometry=true`;

    if (this.selectedYear) {
      url += `&year=${this.selectedYear}`;
    }
    this.blocks = [];
    this.loader = true;
    this.api.getGpsData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.blocks = res;
      },
      error: () => {
        this.selectedBlock = null;
        this.loader = false;
        this.notify.notify("No Blocks found in this Neighbourhood", "warn");
      },
    });
  }

  /**
   *
   * @param layerType
   */
  getLayerData(layerType: any, status: any, initial: boolean = false): void {
    let year = this.selectedYear;
    if (initial) {
      year = new Date().getFullYear();
    }
    this.layerListData = [];

    if (!this.calculate_access.GET) {
      this.notify.notify("You are not authorized to get data", "warn");
      return;
    }

    if (this.fnc.checkResourceAccess("all_uploaded_file").GET) {
      this.is_admin = true;
    }

    this.getAmenities();
    this.list.next();

    let url = `sync-calculate-process-spark?process_name=${this.layerDataGroup.value.layerName}&sort_asc=false&year=${year}&limit=${this.limit}&offset=${this.offset}&is_count=true`;

    if (layerType !== null && this.isPanelOpen) {
      url += `&neigh_uids=${layerType}`;
    }

    if (status !== null && this.isPanelOpen) {
      url += `&status=${status}`;
    }

    this.loader = true;
    this.api.getGesData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        if (res && res.status === 200) {
          this.layerListData = res.data.map(item => ({
            ...item,
            id: item.added_by === 0 ? `auto-${item.id}` : item.id
        }));
        console.log( this.layerListData);

          this.list.next(res.data);
          this.totalCount = res.count || 0; // Update count or fallback to 0
          if (layerType == null && status == null) {
            this.getNeighborhood(res.data);
          }
        } else {
          this.totalCount = res.count || 0; // Update count or fallback to 0
          this.notify.notify(res.message || "Failed to fetch data", "warn");
        }
      },
      error: (err) => {
        console.error("Error fetching data:", err);
        this.loader = false;
        this.layerListData = [];
        this.totalCount = 0; // Reset count on error
      },
    });
  }

  /**
   * filter name list
   * @param datalist
   */
  getNeighborhood(datalist) {
    let list = [];
    let statusList = [];
    datalist.forEach((element) => {
      list.push(element.uid);
      statusList.push(element.status);
    });

    let result = list.reduce(
      (accumulator, value) => accumulator.concat(value),
      []
    );
    this.filteredStatus = statusList.filter(
      (item, index) => statusList.indexOf(item) === index
    );
    const uniqueArr = result.filter(
      (item, index) => result.indexOf(item) === index
    );
    this.filter_list_data = uniqueArr;
  }
  formValueReset() {
    this.layerDataGroup.reset({
      neighbourhoods: "",
      blocks: "",
      distance: "",
      zoning:"",

    });
  }

  calculateSyncProcess() {
    if (!this.calculate_access.POST) {
      this.notify.notify("You are not authorized to calculate data", "warn");
      return;
    }
    if(!this.isServerRunning){
      this.notify.notify("The server must be running to perform the calculation. Please start the server and try again!", "warn");
      return
    }
    const getSelectedType = this.layerDataGroup.value.layerName; // Get selected layer type
    const block = this.layerDataGroup.value.block;
    const distance = this.layerDataGroup.value.distance;
    let zoning = this.layerDataGroup.value.zoning;
    const property_sub_cat = this.layerDataGroup.value.sub_categories || [];

    // Find the selected layer in the list of options
    const selectedLayer = layerListOptions.find(
      (layer) => layer.value === getSelectedType
    );

    if (!selectedLayer) {
      this.notify.notify("Invalid layer selection", "warn");
      this.isCalculating = false;
      return; // Exit if the layer type is not valid
    }

    // Set zoning to the actual value only if `getSelectedType` is `sikka_parcel`
    // Otherwise, set zoning to null
    zoning = getSelectedType === "sikka_parcel" ? zoning : null;

    // Set the appropriate URL based on the selected layer
    const layerTypeUrl = selectedLayer.url;

    // Build the URL for the API request
    let url = `${layerTypeUrl}?process_name=${getSelectedType}&added_by=${this.api.user_id}&year=${this.selectedYear}`;

    // Include zoning in the URL, whether it is a value or explicitly set to `null`
    url += `&zoning=${zoning}`;

    if (this.selectedNeighs) {
      url += `&neigh_uid=${this.selectedNeighs}`;
    }

    if (block?.length > 0) {
      url += `&block_uid=${block}`;
    }
    if (property_sub_cat && property_sub_cat.length > 0) {
      url += `&sub_categories=${property_sub_cat.join(',')}`; // Add sub-categories
    }

    if (
      getSelectedType === "street_rays_180" ||
      getSelectedType === "highway_rays_90" ||
      getSelectedType === "amenity_distance" ||
      getSelectedType === "sikka_parcel"
    ) {
      url += `&distance=${distance}`;
    }
    this.isCalculating = true;
    this.loader = true;
    // Make the API call
    this.api.getGesData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.isCalculating = false;
        if (res && res.status === 200) {
          this.notify.notify(res.message, "success");
          this.getLayerData(null, null); // Refresh the layer data
        } else {
          this.notify.notify(res.message, "warn");
          this.loader = false;
        }
      },
      error: (err) => {
        this.layerListData = [];
        this.notify.notify(err.error.message, "error");
        this.loader = false;
      },
    });
  }


/**
 * Fetch amenities settings dynamically from the API.
 */
getAmenities() {
  const url = `settings?search_text=amenity_distance`;
  this.api.getGmsData(url).subscribe({
    next: (res: any) => {
      if (res.status === 200 && res.data.length) {
        const setting = res.data[0]; // Get the first item in the array
        const values = setting.value.split(','); // Split the value string into an array
        // Map each value to the required structure for the dropdown
        this.sub_categories = values.map((val: string) => ({
          value: val,
          viewValue: val
        }));
      } else {
        this.notify.notify(res.message, "warn");
      }
    },
    error: (err) => {
      console.error(err);
      this.notify.notify("Error fetching amenities", "warn");
    },
  });
}

  openPanel() {
    this.isPanelOpen = !this.isPanelOpen;
  }
  applyFilter(filter_by_name, status) {
    if (filter_by_name == null && status == null) {
      this.notify.notify(
        "Please select at least one option to apply the filter",
        "warn"
      );
      return;
    } else {
      this.getLayerData(filter_by_name, status);
      this.isPanelOpen = false;
    }
  }
  openFilter() {
    this.isPanelOpen = !this.isPanelOpen;
  }
  resetFilter() {
    this.getLayerData(null, null);
    this.isPanelOpen = false;
    this.layerDataGroup.patchValue({
      status: null,
      filter_neighborhood: null,
    });
  }
  layerSelectionReset() {
    this.selectedNeighs = [];
    this.blocks = [];
    this.layerDataGroup.patchValue({
      neighborhood: "",
    });
    this.layerDataGroup.patchValue({
      block: "",
    });
  }
  pageChange(pageDetails) {
    this.limit = pageDetails.limit;
    this.offset = pageDetails.offset;
    this.pageIndex = pageDetails.pageIndex;
    this.getLayerData(null, null);
  }
    // Handles the chosen year for the date picker
    handleYearSelected(year: number) {
      this.selectedYear = year;
      this.getLayerData(null, null);
    }

    checkServerStatus(event){
      this.isServerRunning = event
    }

    getSyncStatus() {
      let apiUrl = `get-blocks-for-calculation`;

      this.api.getGesData(apiUrl).subscribe({
        next: (response: any) => {
          if (response?.status === 200) {
            // const rawBlocks = response.data || [];
            // const formattedBlocks = rawBlocks.map((item: string) => {
            //   const [neighbourhood, block] = item.split(" => ").map(s => s.trim());
            //   return { neighbourhood, block: Number(block) };
            // });

            this.selectedData = response?.data;
            console.log('Formatted Blocks:', this.selectedData);
            this.cdr.detectChanges(); // Force change detection
          } else {
            console.warn('Unexpected response:', response);
          }
        },
        error: (error) => {
          console.error("API Error:", error);
        },
        complete: () => {
          console.log("API call completed.");
        },
      });
    }
}
