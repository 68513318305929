<div class="parent-container" [ngStyle]="{'height':height+'px'}">
  <mat-card appearance="outlined" class="example-card" *ngIf="upload_access.POST">
    <mat-card-header>
      <mat-card-title class="master-title title">Legacy Data Upload</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ngx-loading [show]="loader1" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
      <div class="gis_data_form">
        <form [formGroup]="fileUploadForm" (ngSubmit)="uploadFile(fileUploadForm)">
          <div class="bottom-border" style="display: flex; justify-content: space-between; align-items: center;">
            <div style="display: flex; align-items: end; justify-content: flex-start;" class="radio-btn-container">
              <mat-radio-group formControlName="layer" (change)="initValidators($event)"
                style="display: inline-flex;min-width: 25.5vw;">
                <mat-radio-button class="radio_text" [checked]="check(option)" *ngFor="let option of options"
                  [value]="option.value">{{option.label}}</mat-radio-button>
              </mat-radio-group>

              <ng-container *ngIf="layer == 'layer_data'">
                <mat-select placeholder="Select layer" (selectionChange)="selectedLayer($event.value)"
                  formControlName="selected_layer" class="form-select-l">
                  <mat-option class="cp" *ngFor="let data of layer_list" [value]="data.layer_name">{{data.layer_label}}
                  </mat-option>
                </mat-select>
                &nbsp;&nbsp;
                <mat-select placeholder="Select Language" formControlName="selected_lang" class="form-select-l">
                  <mat-option class="cp" *ngFor="let data of language_list" [value]="data.language_id">{{data.name}}
                  </mat-option>
                </mat-select>
                &nbsp;&nbsp;
                <mat-select placeholder="Select neighbourhood" formControlName="selected_neighbourhood"
                  class="form-select-l" (openedChange)="searchNeigh = ''">
                  <input class="searchIn" mat-input [(ngModel)]="searchNeigh" [ngModelOptions]="{standalone: true}"
                    placeholder="Type Name here to search..." (keydown)="$event.stopPropagation();" />
                  <mat-option class="cp" *ngFor="let data of neighbourhoods | neighFilter:searchNeigh"
                    [value]="data.neigh_uid">
                    {{data.neigh_name}}
                  </mat-option>
                </mat-select>
              </ng-container>
            </div>
          </div>
          <div>
            <div class="gridV">
              <div>
                <span class="fntcustom" *ngIf="fileName">{{fileName}}</span>
              </div>
              <div class="btnGrid">
                <div class="upload-btn-wrapper">
                  <button mat-button class="browesbtn">
                    <mat-icon svgIcon="cloud_upload" class="upicon"></mat-icon> Browse
                  </button>
                  <input type="file" name="myfile" #myFileInput (change)="handleFileInput($event)"
                    formControlName="file" accept=".zip" />
                </div>
                <button style="min-width: 150px;" type="submit" mat-raised-button color="primary"
                  [disabled]="fileUploadForm.invalid">
                  <span *ngIf="!loading">Upload</span>
                  <span *ngIf="loading">Uploading...</span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <!-- download csv -->
        <div *ngIf="layer != null" style="display: flex; align-items: center;top: 10px;position: relative;">
          <mat-select *ngIf="layer == 'layer_data'" multiple placeholder="Select Attributes"
            class="form-select-l multiselectform" [formControl]="selectMultiForm"
            (openedChange)="searchFilter.patchValue('')">
            <input class="searchIn" (keydown)="$event.stopPropagation()" #search matInput focused="'true'" type="text"
              [formControl]="searchFilter" autocomplete="off" placeholder="Search"
              (keydown)="$event.stopPropagation();" />
            <mat-option class="cp" #matOption (click)="selectionChange(matOption.selected,data.attribute_name)"
              *ngFor="let data of filterOption | async" [value]="data.attribute_name">
              {{data.attribute_name}}
            </mat-option>
          </mat-select>
          <button mat-button class="mat-elevation-z2 download-csv" (click)="downloadCsv('layer')">Download
            Template</button>
        </div>
        <!--  -->
      </div>
      <div class="bbar w-100">
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      </div>
    </mat-card-content>

  </mat-card>

  <mat-card appearance="outlined" class="example-card">

    <mat-card-header>
      <mat-card-title class="master-title title">Legacy Data Report</mat-card-title>
    </mat-card-header>
    <mat-card-content class="tabs">
      <div class="bottom-border" style="display: flex; align-items: center; justify-content: flex-start;">
        <button class="margr5" *ngFor="let option of options" [ngClass]="{'active': getActive_(option)}"
          (click)="getUploadedFilesList(option.value, true)">
          {{option.label}}</button>
        <ng-container *ngIf="selectedlayer == 'layer_data'">
          <mat-select placeholder="Select layer" (selectionChange)="selectdata($event)" class="form-select-l"
            [(value)]="filterLayer">
            <mat-option class="cp" *ngFor="let data of layer_list" [value]="data.layer_name">{{data.layer_label}}
            </mat-option>
          </mat-select>
          <!-- Language Selection -->
          <mat-select placeholder="Select Language" class="form-select-l ml-4" [(value)]="selectedLanguage"
            (selectionChange)="onLanguageChange($event)">
            <mat-option class="cp" *ngFor="let data of language_list" [value]="data.language_id">
              {{ data.name }}
            </mat-option>
          </mat-select>
        </ng-container>
      </div>
      <div class="table-estater table">
        <div class="tbar w-100">
          <mat-progress-bar *ngIf="loader" mode="indeterminate"></mat-progress-bar>
          <mat-progress-bar *ngIf="acceptLoader" mode="indeterminate"></mat-progress-bar>
        </div>
        <ng-container *ngIf="selectedlayer && legacyDataReport.length > 0">
          <table class="attr-table table_data">
            <thead>
              <tr>
                <th> S. No. </th>
                <th> Upload Id </th>
                <th> Name </th>
                <th> File Name </th>
                <th> Added Date </th>
                <th> Count</th>
                <th> User Id </th>
                <th> Status </th>
                <th> View Log </th>
                <th> Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let legacyData of legacyDataReport;">
                <td width="5%"> {{legacyData.sn}}</td>
                <td> {{legacyData.upload_id}}</td>
                <td> {{legacyData.name}} </td>
                <td> {{legacyData.file_name}} </td>
                <td> {{legacyData.added_date}} </td>
                <td>{{legacyData.add_count}}</td>
                <td>{{legacyData.user_name}}</td>
                <td [ngClass]="{
                                  'status-invalid': legacyData.status == 'Invalid',
                                  'status-valid': legacyData.status == 'Valid',
                                  'status-accepted': legacyData.status == 'Accepted',
                                  'status-rejected': legacyData.status == 'Rejected',
                                  'status-uploaded': legacyData.status == 'Uploaded',
                                  'status-accept-invalid': legacyData.status == 'Accept Invalid',
                                  'status-accept-progress': legacyData.status == 'Accept Progress'
                                }">
                  {{ legacyData.status }}
                </td>

                <td>
                  <a target="_blank" href="{{legacyData.errfile_link}}" class="bordered" mat-button>
                    Validate Log
                  </a>
                  <a [disabled]="!acceptedlog(legacyData.status)" target="_blank"
                    href="{{legacyData.accept_errfile_link}}" class="bordered ml-1" mat-button>
                    Accept Log
                  </a>
                </td>
                <td>
                  <button [disabled]="!isAccepted(legacyData.status) || checkDis(legacyData.upload_id)"
                    [ngClass]="{btndisabled: !isAccepted(legacyData.status)}" class="bordered prim line-hith" mat-button
                    (click)="action(legacyData, 'accept')">Accept</button>
                  <button [disabled]="!isRejected(legacyData.status)"
                    [ngClass]="{btndisabled: !isRejected(legacyData.status)}" class="bordered warn line-hith" mat-button
                    (click)="action(legacyData, 'reject')">Reject</button>
                  <button [disabled]="!showStopbutton(legacyData.status)"
                    [ngClass]="{btndisabled: !showStopbutton(legacyData.status)}" class="bordered warn line-hith"
                    mat-button (click)="stopprocess(legacyData, 'stop_process')">Stop Process</button>
                </td>

              </tr>
            </tbody>
          </table>
        </ng-container>
      </div>
      <ng-container *ngIf="legacyDataReport.length <= 0">
        <div fxLayout="column" fxLayoutAlign="space-around center">
          <div>
            <i class="material-icons error">error</i>
          </div>
          <div class="notfound">Record not found</div>
        </div>
      </ng-container>
      <mat-paginator [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageAction($event)">
      </mat-paginator>

    </mat-card-content>
  </mat-card>

</div>
